import { Injectable } from '@angular/core';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { ApiNameList } from '../../../environments/api-name-list';
import { UserCacheService } from "./user-cache.service";
import { AppConstants } from "../constants/app-constants";
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable()
export class LogService {

    private _debugLogs = [];
    private _infoLogs = [];
    private _errorLogs = [];
    private _restApi: any;

    constructor(private _location: Location, private logger: NGXLogger, private userCacheService: UserCacheService, private httpClientService: HttpClient) {
        this._restApi = ApiNameList.restApi;
        this.setLogValues();
    }

    private setLogValues() {
        this.logger.updateConfig({ serverLoggingUrl: ApiNameList.restApi.baseUrl + ApiNameList.restApi.LOGGING_API_URL, level: NgxLoggerLevel.OFF, serverLogLevel: NgxLoggerLevel.DEBUG });
        this.logOnEveryTimeInterval();
    }

    /**
     * this method is used to call the writeLog method on every time interval ex-10 secs
     */
    private logOnEveryTimeInterval() {
        setTimeout(() => {
            this.writeLogs();
        }, environment.logDetails.timeInterval);
    }

    /**
     * This method is used to write logs
     */
    private writeLogs() {
        if (this._debugLogs && this._debugLogs.length > 0) {
            this.logger.debug("Debug Logs", this.addAdditionalInputs(this._debugLogs));
            this._debugLogs = [];
        }
        if (this._infoLogs && this._infoLogs.length > 0) {
            this.logger.info("Info logs", this.addAdditionalInputs(this._infoLogs));
            this._infoLogs = [];
        }
        if (this._errorLogs && this._errorLogs.length > 0) {
            this.logger.error("Error logs", this.addAdditionalInputs(this._errorLogs));
            this._errorLogs = [];
        }
        this.logOnEveryTimeInterval();
    }

    /**
     * this method is used to write debug level message
     * @param  {string} message - the message content to be logged
     * @param  {string} transactionType - the class name where the log is written
     * @param  {string} className - the class name where the log is written
     * @param  {string} methodName - the method name where the log is written
     */
    public debug(message: string, transactionType: string, className?: string, methodName?: string) {
        this.appendMessage(message, transactionType, NgxLoggerLevel.DEBUG, className, methodName);
    }

    /**
     * this method is used to write info level message
     * @param  {string} message - the message content to be logged
     * @param  {string} transactionType - the class name where the log is written
     * @param  {string} className - the class name where the log is written
     * @param  {string} methodName - the method name where the log is written
     */
    public info(message: string, transactionType: string, className?: string, methodName?: string) {
        this.appendMessage(message, transactionType, NgxLoggerLevel.INFO, className, methodName);
    }

    /**
     * this method is used to write error level message
     * @param  {string} message - the message content to be logged
     * @param  {string} transactionType - the class name where the log is written
     * @param  {string} className - the class name where the log is written
     * @param  {string} methodName - the method name where the log is written
     */
    public error(message: string, transactionType: string, className?: string, methodName?: string) {
        this.appendMessage(message, transactionType, NgxLoggerLevel.ERROR, className, methodName);
    }


    /**
     * This method is used to add additional inputs like userId and security token
     * @param  {any} entries - the message content to be logged
     */
    private addAdditionalInputs(entries: any) {
        return {
            userId: this.userCacheService.getUserData(AppConstants.USERID),
            securityToken: this.userCacheService.getUserData(AppConstants.JWT_TOKEN),
            clientCorrelationId: this.userCacheService.getUserData(AppConstants.ClientCorrelationId),
            brandId: this.userCacheService.getUserData(AppConstants.BRAND) ? this.userCacheService.getUserData(AppConstants.BRAND) : '',
            mbu: this.userCacheService.getUserData(AppConstants.MBU) ? this.userCacheService.getUserData(AppConstants.MBU) : '',
            sourceSystem: this.userCacheService.getUserData(AppConstants.SRCSYSTEM) ? this.userCacheService.getUserData(AppConstants.SRCSYSTEM) : '',
            groupNumber: this.userCacheService.getUserData(AppConstants.GROUPNUMBER) ? this.userCacheService.getUserData(AppConstants.GROUPNUMBER) : '',
            subscriberId: this.userCacheService.getUserData(AppConstants.SUBSCRIBERID) ? this.userCacheService.getUserData(AppConstants.SUBSCRIBERID) : '',
            entries: entries
        };
    }


    /**
     * this method is used to append the log entry to the corresponding array variables
     * @param  {string} message
     * @param  {string} transactionType
     * @param  {NgxLoggerLevel} logLevel
     * @param  {string} className - the class name where the log is written
     * @param  {string} methodName - the method name where the log is written
     */
    private appendMessage(message: string, transactionType: string, logLevel: NgxLoggerLevel, className?: string, methodName?: string) {
        switch (logLevel) {
            case NgxLoggerLevel.DEBUG:
                this._debugLogs.push(this.formatMessage(message, transactionType, logLevel, className, methodName));
                break;
            case NgxLoggerLevel.INFO:
                this._infoLogs.push(this.formatMessage(message, transactionType, logLevel, className, methodName));
                break;
            case NgxLoggerLevel.ERROR:
                this._errorLogs.push(this.formatMessage(message, transactionType, logLevel, className, methodName));
                break;

            default:
                break;
        }
    }
    /**
     * This method is used to format the log message into specific format
     * @param  {string} message
     * @param  {string} transactionType
     * @param  {NgxLoggerLevel} logLevel
     * @param  {string} className - the class name where the log is written
     * @param  {string} methodName - the method name where the log is written
     */
    private formatMessage(message: string, transactionType: string, logLevel: NgxLoggerLevel, className?: string, methodName?: string) {
        return {
            timestamp: new Date(),
            message: message,
            transactionType: transactionType ? transactionType : '',
            className: className ? className : '',
            methodName: methodName ? methodName : '',
            level: logLevel,
            url: this._location.path(),
            userAgent: ''
        };
    }
}