import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { DynamicLoadModule } from './framework/dynamic-load.module';
import { UserCacheService } from './common/services/user-cache.service';
import { WcsApiService } from './common/services/wcs-api.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { IdentityService } from './common/services/eea/identity.service';
import { HttpClientService } from './common/services/http-client.service';

import { ReusableWcsWidgetsModule } from './common/components/reusable-wcs-widgets/reusable-wcs-widgets.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErLibraryModule } from 'anthem-eea-library-ui';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { LogService } from './common/services/log-Service';
import { HttpResponseTimeLoggerService } from './common/services/http-response-time-logger.service';
import { DataAnalyticsService } from './common/services/data-analytics.service';
import { ChatComponent } from './common/components/chat/chat.component';
import { ChatHandler } from './common/services/chat.service';
import { SliderService } from './common/services/slider-service';
import { ReusablePageWidgetsModule } from './common/components/reusable-page-widgets/reusable-page-widgets.module';
import { RouterModule } from '@angular/router';
@NgModule({ declarations: [
        AppComponent,
        ChatComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent], imports: [RouterModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        DynamicLoadModule,
        ReusableWcsWidgetsModule,
        ErLibraryModule.forRoot(),
        BrowserAnimationsModule,
        LoggerModule.forRoot({ level: NgxLoggerLevel.OFF }),
        ReusablePageWidgetsModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpResponseTimeLoggerService,
            multi: true
        },
        UserCacheService,
        WcsApiService,
        IdentityService,
        HttpClientService,
        { provide: 'Window', useValue: window },
        LogService,
        DataAnalyticsService,
        ChatHandler,
        SliderService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
